import React from 'react';

import css from './MarketplaceIntroduction.module.css';
import { NamedRedirect, NamedLink } from '../../components';
import { useHistory } from 'react-router-dom';
import { ReactComponent as SearchIcon } from './images/searchIcon.svg'; // Import the SVG file
import { ReactComponent as BloombergIcon } from './images/New_Bloomberg_Logo.svg';
import { ReactComponent as BbcIcon } from './images/BBC_Logo_2021.svg';
import { ReactComponent as UsaTodayIcon } from './images/USA_Today_logo_PNG1.svg';
import { ReactComponent as CnnIcon } from './images/CNN-logo-500x281.svg';
import { ReactComponent as ForbesIcon } from './images/Forbes_logo.svg';
import { ReactComponent as YahooIcon } from './images/Yahoo-logo-500x281.svg';
import { ReactComponent as MsnIcon } from './images/2015_MSN_logo.svg.svg';
import { ReactComponent as BusinessInsiderIcon } from './images/Business_Insider_Logo.svg';

const MarketplaceIntroduction = props => {
  const history = useHistory();

  const handleCtaClick = () => {
    history.push('/s');
  };

  return (
    <div className={css.backdrop}>
      <div className={css.backdropImage}>
        <div className={css.hero}>
          <div className={css.heroSections}>
            <div className={css.heroContent}>
              <div className={css.heroSubSection}>
                <div className={css.heroInfo}>
                  <p className={css.text}>Connecting Food and Influence</p>
                  <h1 className={css.title}>
                    The Place Where Restaurants and Food Influencers Meet
                  </h1>

                  <div className={css.searchContainer}>
                    <input type="text" className={css.searchInput} placeholder="Search" />
                    <SearchIcon className={css.searchIcon} />
                  </div>
                  <div className={css.buttons}>
                    <button className={css.primaryButton} onClick={handleCtaClick}>
                      Find Your Influencer
                    </button>
                    <NamedLink name={'SignupPage'} className={css.ctaLink}>
                      Join as an Influencer
                    </NamedLink>
                  </div>
                </div>
              </div>
            </div>
            <div className={css.heroContent}>
              <img
                src="static/images/hamburger.png"
                alt="Hamburger Image"
                className={css.hamburger}
              />
              <img src="static/images/camera.png" alt="Camera Image" className={css.camera} />
              <img src="static/images/bag.png" alt="Bag Image" className={css.bag} />
              <img src="static/images/Hero_Image.jpeg" alt="Hero Image" className={css.heroImage} />
            </div>
          </div>
        </div>
        <div className={css.heroFooter}>
          <p className={css.footerText}>AS SEEN ON:</p>

          <BloombergIcon />
          <BbcIcon />
          <UsaTodayIcon />
          <CnnIcon />
          <ForbesIcon />
          <YahooIcon />
          <MsnIcon />
          <BusinessInsiderIcon />
        </div>
      </div>
    </div>
  );
};

MarketplaceIntroduction.defaultProps = {};

MarketplaceIntroduction.propTypes = {};

export default MarketplaceIntroduction;
