import React from 'react';

import css from './DinelySteps.module.css';
import { NamedRedirect, NamedLink } from '../../components';
import { useHistory } from 'react-router-dom';
import { ReactComponent as RightArrowIcon } from './images/RightArrowIcon.svg';

const workingSteps = [
  {
    title: 'Sign Up',
    description: 'Join as a restaurant or an influencer.',
  },
  {
    title: 'Connect',
    description: 'Browse Influencers or Earn by Collaborating with Restaurants on TasteTribe.',
  },
  {
    title: 'Collaborate',
    description: 'Plan and execute successful campaigns.',
  },
  {
    title: 'Grow',
    description: 'See results and build lasting partnerships.',
  },
];

const DinelySteps = props => {
  const history = useHistory();

  const handleCtaClick = () => {
    history.push('/s');
  };

  return (
    <div className={css.backdrop}>
      <div className={css.content}>
        <h1 className={css.title}>How TasteTribe Works </h1>
        <div className={css.cardContainer}>
          {workingSteps.map((card, index) => {
            return (
              <div key={index} className={css.card}>
                <div>
                  <h3 className={css.cardTitle}>{card.title}</h3>
                  <p className={css.cardDescription}>{card.description}</p>
                </div>
                {index !== workingSteps.length - 1 && <RightArrowIcon className={css.arrow} />}
              </div>
            );
          })}
        </div>
      </div>
      <img src="static/images/rocket.png" alt="Rocket Image" className={css.rocket} />
    </div>
  );
};

DinelySteps.defaultProps = {};

DinelySteps.propTypes = {};

export default DinelySteps;
