import React from 'react';

import css from './SignupSection.module.css';
import { NamedRedirect, NamedLink } from '../../components';
import { useHistory } from 'react-router-dom';

const SignupSection = props => {
  const history = useHistory();

  const handleCtaClick = () => {
    history.push('/s');
  };

  return (
    <div className={css.backdrop}>
      <div className={css.backdropImage}>
        <div className={css.hero}>
          <div className={css.heroSections}>
            <div className={css.heroContent}>
              <div className={css.heroSubSection}>
                <div className={css.heroInfo}>
                  <h1 className={css.title}>Ready to Start?</h1>
                  <p className={css.text}>
                    Join TasteTribe today and start making impactful connections that will grow your
                    brand and influence. Sign up now and be part of the revolution in food
                    marketing.
                  </p>
                  <div className={css.buttons}>
                    <button className={css.primaryButton} onClick={handleCtaClick}>
                      Sign Up Now
                    </button>
                    <NamedLink name={'SignupPage'} className={css.ctaLink}>
                      Learn more
                    </NamedLink>
                  </div>
                </div>
              </div>
            </div>
            <div className={css.heroContent}>
              <img
                src="static/images/backgroundImage.png"
                alt="Hero Image"
                className={css.heroImage}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

SignupSection.defaultProps = {};

SignupSection.propTypes = {};

export default SignupSection;
